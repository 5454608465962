import { loadingButtonClasses } from '@mui/lab'
import { ButtonProps, RadioProps } from '@mui/material'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import type { } from '@mui/x-data-grid-pro/themeAugmentation'
import merge from 'deepmerge'
import { transparentize } from 'polished'

import { resolveThemeColor, theme as acreTheme } from '@acre/design-system'

const SPACING_UNIT = 8
const HTML_FONT_SIZE = 16

const spacing = (factor: number) => `${(SPACING_UNIT / HTML_FONT_SIZE) * factor}rem`

const muiClientPortalTheme: ThemeOptions = {
  mixins: {
    sidebar: {
      width: spacing(35),
      height: spacing(9.5),
    },
  },
  palette: {
    primary: { main: acreTheme.colours.new.primaryColour },
    secondary: { main: acreTheme.colours.new.baseMidColour, light: acreTheme.colours.new.grey3 },
    error: { main: acreTheme.colours.danger },
    info: { main: acreTheme.colours.new.baseExtraLight },
    text: {
      primary: acreTheme.colours.new.baseColour,
      secondary: acreTheme.colours.new.baseMidColour,
    },
  },
  typography: {
    fontFamily: acreTheme.typography.fontFamilyGrotesk,
    fontWeightRegular: acreTheme.typography.fontWeight,

    htmlFontSize: HTML_FONT_SIZE,
    button: {
      lineHeight: 1.6,
    },
    h1: {
      fontSize: '2.625rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: '1.625rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: '0.875rem',
      lineHeight: 1.4,
    },
  },
  shape: { borderRadius: 6 },
  spacing,
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1.25),
          padding: `${theme.spacing(2)} ${theme.spacing(2)}}`,
        }),
        message: ({ theme }) => ({
          display: 'flex',
          alignItems: 'center',
          padding: '0px',
          fontWeight: theme.typography.fontWeightMedium,
        }),
        icon: ({ theme }) => ({
          padding: '0px',
          marginRight: theme.spacing(1),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          borderRadius: theme.spacing(1.25),
          padding: theme.spacing(1),
          backgroundColor: theme.colours.new.baseColour,
          color: theme.colours.new.baseExtraLight,
          fontSize: theme.typography.pxToRem(12),
          fontWeight: theme.typography.fontWeightRegular,
        }),
        arrow: ({ theme }) => ({
          color: theme.colours.new.baseColour,
        }),
      },
    },
    Button: {
      variants: [
        {
          props: { variant: 'clear' },
          style: ({ theme }) => ({
            '&:hover': {
              background: theme.palette.background.paper,
              color: theme.colours.black,
            },
          }),
        },
        {
          props: { variant: 'white' },
          style: ({ theme }) => ({
            color: theme.colours.black,
            background: theme.palette.background.paper,
            '&:hover': {
              background: theme.colours.ghostWhite,
            },
          }),
        },
      ],
      styleOverrides: {
        root: ({ theme, loading }) => ({
          fontSize: theme.typography.pxToRem(16),
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightBold,

          '&:disabled': {
            cursor: loading ? 'progress' : 'not-allowed',
            pointerEvents: 'auto',
          },
          height: theme.spacing(6),
          padding: `${theme.spacing(1.75)} ${theme.spacing(3)}`,
        }),
        primary: ({ theme, color }) => ({
          backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main'),
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),

          '&:disabled': {
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.8, color),
            ),
          },

          '&:hover:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.1, color),
            ),
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
          },

          '&:active:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'dark'),
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
          },

          [`& .${loadingButtonClasses.loadingIndicator}`]: {
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
          },
        }),
        secondary: ({ theme, color }) => ({
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundColor: theme.colours.foreground,
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'secondary', 'main'),

          '&:disabled': {
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'secondary', 'main', (color) =>
              transparentize(0.7, color),
            ),

            '&:hover': {
              backgroundColor: theme.colours.foreground,
            },
          },

          '&:hover:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'secondary', 'main', (color) =>
              transparentize(0.9, color),
            ),
          },

          '&:active:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'secondary', 'main', (color) =>
              transparentize(0.8, color),
            ),
          },
        }),
        text: ({ theme, color, loading }) => ({
          '&:disabled': {
            ...(!loading && {
              color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
                transparentize(0.8, color),
              ),
            }),
          },
        }),
        link: ({ theme, color, loading }) => ({
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main'),

          '&:disabled': {
            ...(!loading && {
              color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
                transparentize(0.8, color),
              ),
            }),
          },
        }),
        outlined: ({ theme, loading }) => ({
          color: theme.colours.new.baseMidColour,
          borderColor: theme.colours.new.baseMidColour,
          borderStyle: 'solid',
          borderWidth: 1,

          '&:disabled': {
            ...(!loading && {
              backgroundColor: 'white',
            }),
          },

          '&:hover:not(:disabled)': {
            ...(!loading && {
              color: theme.colours.new.baseMidColour,
              borderColor: theme.colours.new.baseMidColour,
              backgroundColor: theme.colours.new.baseExtraLight,
            }),
          },

          '&:active:not(:disabled)': {
            color: theme.colours.new.baseMidColour,
            borderColor: theme.colours.new.baseMidColour,
            backgroundColor: theme.colours.new.baseLightColour,
          },

          '&.danger': {
            color: theme.colours.danger,
            borderColor: theme.colours.danger,
            borderStyle: 'solid',
            borderWidth: 1,
          },

          '&.danger:hover': {
            color: theme.colours.danger,
            borderColor: theme.colours.danger,
            borderStyle: 'solid',
            borderWidth: 1,
          },
        }),
      },
    },

    Radio: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme, color }) => ({
          padding: '0px',
          paddingRight: theme.spacing(2),
          '&.checked': {
            borderColor: resolveThemeColor(theme, (color as RadioProps['color']) || 'primary', 'main'),
            backgroundColor: resolveThemeColor(theme, (color as RadioProps['color']) || 'primary', 'light'),
          },
          '&:hover': {
            borderColor: resolveThemeColor(theme, (color as RadioProps['color']) || 'primary', 'main'),
          },
          '&.disabled': {
            backgroundColor: theme.colours.baseExtraLight,
            color: theme.colours.baseMid,

            '&:hover': {
              color: theme.colours.baseMid,
            }
          },
        }),
        circle: ({ theme }) => ({
          marginLeft: theme.spacing(2),
        }),
        circleChecked: ({ theme, color, disabled }) => ({
          borderColor: disabled ? theme.colours.baseLight : resolveThemeColor(theme, (color as RadioProps['color']) || 'primary', 'main'),
          backgroundColor: disabled ? theme.colours.baseLight : resolveThemeColor(theme, (color as RadioProps['color']) || 'primary', 'main'),
        }),
      },
    },
  },
}

const theme = createTheme(merge(acreTheme, muiClientPortalTheme))

// SHADOWS
theme.shadows[1] = 'rgb(0 0 0 / 10%) 0px 4px 8px'
theme.shadows[2] = acreTheme.boxShadow
theme.shadows[3] = acreTheme.clientBoxShadow

export default theme
