import { createContext, useContext } from 'react'

import { Property } from '@acre/graphql'

export const PropertyCaseContext = createContext<Property>({} as Property)

export const usePropertyCaseContext = () => {
  const context = useContext<Property>(PropertyCaseContext)
  if (!context) {
    throw new Error('usePropertyContext must be used within usePropertyContext')
  }
  return context
}
