import { useCallback, useEffect, useState } from 'react'
import mixpanel, { Config } from 'mixpanel-browser'

import config from '@acre/config'

import { MixPanelGlobals, UseMixPanelResult } from './mixpanel.types'

const getEnv = () => {
  switch (config.API_URL) {
    case 'https://api.acre.software/v1/acre':
      return 'integration'
    case 'https://api.acreplatforms.co.uk/v1/acre':
      return 'staging'
    case 'https://api.myac.re/v1/acre':
      return 'prod'
  }
  return 'unknown'
}

// @ts-expect-error - Cypress is a global variable
const shouldDisableMixpanel = window.Cypress || import.meta.env.VITEST || import.meta.env.CI

// This hook allows you to import helper methods for calling Mixpanel
//
// Generally you should use the more specific hook in the common directories
// of broker-crm or client-portal as appropriate as they append info about the
// user.
export const useMixPanel = (debug: boolean = false, initConfig: Partial<Config> = {}): UseMixPanelResult => {
  const [mpStatus, setMPStatus] = useState(false)
  const [sessionGlobals, setSessionGlobals] = useState<MixPanelGlobals>({} as MixPanelGlobals)

  // trackEvent does exactly what it says - tracks an event
  const trackEvent = useCallback(
    (eventName: string, properties?: MixPanelGlobals) => {
      if (shouldDisableMixpanel) {
        try {
          mixpanel.disable()
        } catch (e) {
          // do nothing
        }
        return
      }

      if (!mpStatus) {
        console.error('Mixpanel not initialised')
        return
      }
      mixpanel.track(eventName, Object.assign(sessionGlobals, properties ? properties : {}))
    },
    [mpStatus, sessionGlobals],
  )

  // wrapEventHandlerWithEvent wraps a click handler with a mixpanel tracker
  const wrapEventHandlerWithEvent = (
    handler: (e: Event) => void,
    eventName: string,
    properties?: MixPanelGlobals,
  ): ((e: Event) => void) => {
    return (e: Event) => {
      mixpanel.track(eventName, properties)
      handler(e)
    }
  }

  // setGlobal sets a property to be appended to all events for this user, either during this
  // session or persistently
  const setGlobal = useCallback(
    (key: string, value: string | number, persistBetweenSessions: boolean = false) => {
      const opts = {} as MixPanelGlobals
      opts[key] = value
      if (persistBetweenSessions) {
        mixpanel.register(opts)
        return
      }
      setSessionGlobals(Object.assign(sessionGlobals, opts))
    },
    [setSessionGlobals, sessionGlobals],
  )

  const getGlobal = useCallback(
    (key: string) => {
      if (sessionGlobals[key]) {
        return sessionGlobals[key]
      }
      return mixpanel.get_property(key)
    },
    [sessionGlobals],
  )

  useEffect(() => {
    if (shouldDisableMixpanel) {
      return
    }

    if (mpStatus) {
      return
    }
    mixpanel.init(config.MIXPANEL_TOKEN, {
      // when this is set, it tells you what's going on in dev tools
      debug,
      // our users have consented to being tracked already
      ignore_dnt: true,
      // don't store info on http!
      secure_cookie: true,
      loaded: () => {
        // set Environment super property on load so that it will only be called once.
        setGlobal('Environment', getEnv(), true)
      },
      ...initConfig,
    })
    setMPStatus(true)
    setGlobal('Environment', getEnv(), false)
  }, [debug, mpStatus, setGlobal, initConfig])

  return {
    isDisabled: shouldDisableMixpanel,
    trackEvent,
    wrapEventHandlerWithEvent,
    setGlobal,
    getGlobal,
    ready: mpStatus,
  }
}
