import { useEffect } from 'react'
import { useClientContext } from '@client-portal-contexts'
import mixpanel from 'mixpanel-browser'

import { CaseStatus, Maybe, MortgageReason } from '@acre/graphql'
import { mixPanelGlobals, MixPanelType, useMixPanel as mixPanelHook } from '@acre/design-system'

export type returnType = MixPanelType & {
  appendCaseProperties: (properties: mixPanelGlobals, caseDetails?: caseShape) => mixPanelGlobals
}

type caseShape = {
  id?: string | null
  preference_mortgage_reason?: Maybe<MortgageReason>
  status?: Maybe<CaseStatus>
} | null

// @ts-expect-error - Cypress is a global variable
const shouldDisableMixpanel = window.Cypress || import.meta.env.VITEST || import.meta.env.CI

// This hook allows you to import helper methods for calling Mixpanel
// It wraps the similarly named hook in @acre/components wherein you'll
// find more details of how to use it.
export const useMixPanel = (debug: boolean = false): returnType => {
  const client = useClientContext(true)
  const mpAPI = mixPanelHook(debug, {
    track_pageview: 'url-with-path-and-query-string',
  })
  const useOathKeeper = sessionStorage.getItem('useOathKeeper') === 'true'

  // append info about the user in a persistent way
  useEffect(() => {
    if (!client || (window as any).Cypress) {
      return
    }

    if (shouldDisableMixpanel) {
      try {
        mixpanel.disable()
      } catch (e) {
        // do nothing
      }
      return
    }

    if (mixpanel.has_opted_out_tracking()) {
      // is this cool in client portal? Everyone is going to have dnt on now...
      mixpanel.opt_in_tracking()
    }
    mixpanel.identify(client.id)
    mixpanel.people.set_once({
      'Client Portal User': true,
    })
  }, [client])

  // This function puts a standard set of properties about a case onto an event's properties
  const appendCaseProperties = (properties: { [key: string]: string | number }, caseDetails?: caseShape | null) => {
    if (!caseDetails) {
      return properties
    }
    return Object.assign(properties, {
      'Case ID': caseDetails.id || '',
      'Case Type': caseDetails.preference_mortgage_reason || '',
      'Case status': caseDetails.status || '',
      'CP new login': useOathKeeper,
    })
  }

  return Object.assign(
    {
      appendCaseProperties,
    },
    mpAPI,
  ) as returnType
}
